import config from "../config";
import axios from "axios";
import firebase from "../context/authContext/firebase";
import { getAuth, deleteUser } from "firebase/auth";

export const createPostBox = async (postBox) => {
  const json_data = {
    box_address: postBox.box_address,
    building_id: postBox.building_id,
    display_content: postBox.display_content,
    is_common_box: postBox.is_common_box,
    box_height: postBox.box_height,
    box_width: postBox.box_width,
    box_length: postBox.box_length,
  };
  if (postBox.box_serial) json_data.box_serial = postBox.box_serial;

  const options = {
    url: config.APPLICATION_URL + "adminApp/box",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    return await axios(options);
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const updatePostBox = async (postBox) => {
  const json_data = {
    box_address: postBox.box_address,
    box_id: postBox.box_id,
    building_id: postBox.building_id,
    display_content: postBox.display_content,
    is_common_box: postBox.is_common_box,
    box_height: postBox.box_height,
    box_width: postBox.box_width,
    box_length: postBox.box_length,
  };
  if (postBox.box_serial) json_data.box_serial = postBox.box_serial;

  if (postBox.box_serial_deleted)
    json_data.box_serial_deleted = postBox.box_serial_deleted;

  const options = {
    url: config.APPLICATION_URL + "adminApp/box",
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    return await axios(options);
  } catch (err) {
    console.log(err);
  }
};

export const deletePostBox = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/box/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    return await axios(options);
  } catch (err) {
    console.log(err);
  }
};

export const unlockPostbox = async (id) => {
  const json_data = {
    box_id: parseInt(id) ?? 0,
  };
  const options = {
    url: config.APPLICATION_URL + "adminApp/box/unlock",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    return await axios(options);
  } catch (err) {
    console.log(err);
  }
};



export const createBuilding = async (building) => {
  const json_data = {
    building_name: building.building_name,
    city: building.city,
    state: building.state,
    street: building.street,
    zip: building.zip,
    customer_id: building.customer_id,
    organization_number: building.organization_number,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/building`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const updateBuilding = async (building) => {
  const json_data = {
    building_id: building.building_id,
    building_name: building.building_name,
    city: building.city,
    state: building.state,
    street: building.street,
    zip: building.zip,
    organization_number: building.organization_number,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/building`,
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res;
  } catch (err) {}
  return null;
};

export const deleteBuilding = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/building/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    return await axios(options);
  } catch (err) {
    console.log(err);
  }
};

export const createInstaller = async (installer) => {
  const json_data = {
    building_id: installer.building_id,
    customer_id: installer.customer_id,
    email: installer.email,
    user_id: installer.user_id,
    valid_from: installer.valid_from,
    valid_to: installer.valid_to,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/installer`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const deleteInstaller = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/installer/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const createContact = async (buildingId, contact) => {
  const json_data = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    email: contact.email,
    phone_number: contact.phone_number,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/${buildingId}/contact`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const updateContact = async (contact) => {
  const json_data = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    email: contact.email,
    phone_number: contact.phone_number,
    contact_id: contact.contact_id,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/contact`,
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const createResident = async (resident, isCSV = false) => {
  let json_data = {
    box_id: resident.box_id,
    box_text: resident.box_text,
    box_address: resident.box_address,
    building_id: resident.building_id,
    customer_id: resident.customer_id,
    email: resident.email,
    first_name: resident.first_name,
    last_name: resident.last_name,
    phone: resident.phone,
    smartcard_devices: resident.smartcard_devices,
  };
  if (isCSV) {
    json_data = await csvResidentData(resident);
  }

  const options = {
    url: config.APPLICATION_URL + `adminApp/resident`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res;
  } catch (err) {}
  return null;
};

const csvResidentData = async (resident) => {
  resident.smartcard_devices = [];
  const smartCardList = [
    resident.KortID1 ?? resident.smartcard_device1,
    resident.KortID2 ?? resident.smartcard_device2,
    resident.KortID3 ?? resident.smartcard_device3,
    resident.KortID4 ?? resident.smartcard_device4,
    resident.KortID5 ?? resident.smartcard_device5,
    resident.KortID6 ?? resident.smartcard_device6,
  ];
  Object.keys(smartCardList).forEach((key) => {
    const value = smartCardList[key];
    if (value !== null && value !== undefined && value !== "") {
      resident.smartcard_devices.push(value);
    }
  });

  const json_data = {
    box_address: resident.PostkasseID ?? resident.box_address,
    email: resident.Epost ?? resident.email,
    first_name: resident.Fornavn ?? resident.first_name,
    last_name: resident.Etternavn ?? resident.last_name,
    phone: resident.Telefon ?? resident.phone,
    //'box_id': parseInt(resident.box_id) ?? 0,
    building_id: parseInt(resident.building_id) ?? 0,
    customer_id: parseInt(resident.customer_id) ?? 0,
    box_text:
      resident.Postkassetekst ??
      resident.box_text ??
      resident.first_name + " " + resident.last_name,
    smartcard_devices: resident.smartcard_devices,
  };

  console.log("json_data", json_data);
  return json_data;
};

export const updateResident = async (resident) => {
  const json_data = {
    box_id: resident.box_id,
    customer_id: resident.customer_id,
    email: resident.email,
    phone: resident.phone,
    first_name: resident.first_name,
    last_name: resident.last_name,
    user_id: resident.user_id,
    resident_id: resident.resident_id,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/resident`,
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res;
  } catch (err) {}
  return null;
};

export const deleteResident = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/resident/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const createAccess = async (access) => {
  const json_data = {
    access_name: access.access_name,
    access_token: access.access_token,
    box_id: access.box_id,
    device_id: access.device_id,
    device_type: "Smartcard",
    is_valid: true,
    resident_id: access.resident_id,
    user_id: access.user_id,
    valid_from: access.valid_from,
    valid_to: access.valid_to,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/access`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res;
  } catch (err) {}
  return null;
};

export const deleteAccess = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/access/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const createCustomer = async (customer) => {
  const json_data = {
    customer_name: customer.customer_name,
    parent_customer_id: customer.parent_customer_id,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/customer`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const updateCustomer = async (customer) => {
  const json_data = {
    customer_id: customer.customer_id,
    customer_name: customer.customer_name,
    parent_customer_id: customer.parent_customer_id,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/customer`,
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const deleteCustomer = async (id) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/customer/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  try {
    const res = await axios(options);
    return res.data;
  } catch (err) {}
  return null;
};

export const createUser = async (user) => {
  try {
    const newUser = await createUserInFirebase(user);
    if (newUser && newUser.uid) {
      const json_data = {
        customer_id: user.customer_id,
        email: user.email,
        firebase_uid: newUser.uid,
        name: user.name,
        active: true,
      };
      const options = {
        url: config.APPLICATION_URL + `adminApp/user`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
        data: json_data,
      };
      const res = await axios(options);
      return res.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUser = async (user) => {
  const json_data = {
    customer_id: user.customer_id,
    firebase_uid: user.firebase_uid,
    name: user.name,
    id: user.id,
    email: user.email,
  };
  const options = {
    url: config.APPLICATION_URL + `adminApp/user`,
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
    data: json_data,
  };
  try {
    return await axios(options);
  } catch (err) {}
  return null;
};

export const deleteUserById = async (user) => {
  const options = {
    url: config.APPLICATION_URL + `adminApp/user/${user.id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };
  const auth = getAuth();
  auth.onAuthStateChanged(() => {
    deleteUser(auth, user.firebase_uid)
      .then(() => {
        const res = axios(options);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

const createUserInFirebase = async (user) => {
  try {
    const newUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);
    return newUser.user;
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password);
        const updatedUser = firebase.auth().currentUser;
        await updatedUser.updatePassword(user.password);
        console.log("Password updated:", updatedUser.uid);
        return updatedUser;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
